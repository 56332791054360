import "./Assets/style/base.css";
import "./Assets/style/elements.css";
import "./Assets/style/main.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import routes from "./Routes";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { persistor, store } from "./Services/store";
import { TfiFullscreen } from "react-icons/tfi";
import { PersistGate } from "redux-persist/integration/react";


function App() {

  var elem = document.documentElement;
  const openFullscreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    } else {
      alert("end")
    }
  }
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense>
          <button id="fullScreenMode" onClick={openFullscreen}><TfiFullscreen /></button>
          <RouterProvider router={routes} />
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;