import React, { lazy } from "react";
import { createBrowserRouter } from 'react-router-dom';
import ProtectedRoutes from "./ProtectedRoutes";
import UnProtectedRoutes from "./UnProtectedRoutes";

const AdminPage = lazy(() => import("../Pages/Admin"))
const OpinionManage = lazy(() => import("../Pages/Admin/OpinionManage"))
const QuestionaireManage = lazy(() => import("../Pages/Admin/QuestionaireManage"))
const ReportManage = lazy(() => import("../Pages/Admin/ReportManage"))
const HomePage = lazy(() => import("../Pages/HomePage"))
const CreateAccount = lazy(() => import("../Pages/CreateAccount"))
const Login = lazy(() => import("../Pages/Login"))
const Authentication = lazy(() => import("../Pages/Authentication"))
const PasswordReset = lazy(() => import("../Pages/PasswordReset"))
const EnterNewPassword = lazy(() => import("../Pages/EnterNewPassword"))
const ProfileRegistry = lazy(() => import("../Pages/ProfileRegistry"))
const PersonalDetail = lazy(() => import("../Pages/PersonalDetails"))
const InitialRegistration = lazy(() => import("../Pages/InitialRegistration"))
const ProfileSetting = lazy(() => import("../Pages/ProfileSettings"))
const SettingPage = lazy(() => import("../Pages/SettingPage"))
const MyProfileDetail = lazy(() => import("../Pages/MyProfileDetail"))
const Recommendation = lazy(() => import("../Pages/Recommendation"))
const FromTheOtherParty = lazy(() => import("../Pages/FromTheOtherParty"))
const MatchInProgress = lazy(() => import("../Pages/MatchInProgress"))
const TalkPage = lazy(() => import("../Pages/TalkPage"))
const ProfileDetail = lazy(() => import("../Pages/ProfileDetail"))
const ChatPage = lazy(() => import("../Pages/ChatPage"))
const AgeConfirmation = lazy(() => import("../Pages/AgeConfirmation"))
const AnemonePlus = lazy(() => import("../Pages/PlanPage/AnemonePlus"))
const PlatinumOption = lazy(() => import("../Pages/PlanPage/PlatinumOption"))
const SecretMode = lazy(() => import("../Pages/PlanPage/SecretMode"))
const BillingManagement = lazy(() => import("../Pages/BillingManagement"))
const ReportSubmission = lazy(() => import("../Pages/ReportSubmission"))
const Notification = lazy(() => import("../Pages/Notification"))
const VariousSettings = lazy(() => import("../Pages/VariousSettings"))
const NotificationSettings = lazy(() => import("../Pages/NotificationSettings"))
const EmailAddressChange = lazy(() => import("../Pages/EmailAddressChange"))
const PasswordChange = lazy(() => import("../Pages/PasswordChange"))
const SearchConditions = lazy(() => import("../Pages/SearchConditions"))
const Withdrawn = lazy(() => import("../Pages/Withdrawn"))
const WithdrawalQuestionnaire = lazy(() => import("../Pages/WithdrawalQuestionnaire"))
const SecretModeSettings = lazy(() => import("../Pages/SecretModeSettings"))
const AgeVerification = lazy(() => import("../Pages/AgeConfirmation/AgeVerification"))
const HealthInsuranceCardOthers = lazy(() => import("../Pages/AgeConfirmation/HealthInsuranceCardOthers"))
const WithdrawalProceduresNotes = lazy(() => import("../Pages/WithdrawalProceduresNotes"))
const Payment = lazy(() => import("../Pages/Payment"))
const InquiryForm = lazy(() => import("../Pages/InquiryForm"))
const BaseInfoChoices = lazy(() => import("../Pages/BaseInfoChoices"))
const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomePage />,
        title: "home",
        priv: false,
    },
    {
        element: <UnProtectedRoutes />,
        children: [
            {
                path: "/signup",
                element: <CreateAccount />,
                title: "signup",
                priv: false,
            },
            {
                path: "/login",
                element: <Login />,
                title: "login",
                priv: false,
            },
            {
                path: "/verify-code",
                element: <Authentication />,
                title: "verify-code",
                priv: false,
            },
            {
                path: "/password-reset",
                element: <PasswordReset />,
                title: "password-reset",
                priv: false,
            },
            {
                path: "/enter-new-password",
                element: <EnterNewPassword />,
                title: "enter-new-password",
                priv: false,
            },
            {
                path: "/profile-registry",
                element: <ProfileRegistry />,
                title: "profile-registry",
                priv: true,
            },
            {
                path: "/personal-detail",
                element: <PersonalDetail />,
                title: "personal-detail",
                priv: true,
            },
            {
                path: "/initial-registration",
                element: <InitialRegistration />,
                title: "initial-registration",
                priv: true,
            },

        ]
    },
    {
        element: <ProtectedRoutes />,
        children: [
            {
                path: "/profile-setting",
                element: <ProfileSetting />,
                title: "profile-setting",
                priv: true,
            },
            {
                path: "/baseinfo-choices/:type",
                element: <BaseInfoChoices />,
                title: "baseinfo-choices",
                priv: true
            },
            {
                path: "/recommendation",
                element: <Recommendation />,
                title: "recommendation",
                priv: true,
            },
            {
                path: "/from-the-other-party",
                element: <FromTheOtherParty />,
                title: "from-the-other-party",
                priv: true,
            },
            {
                path: "/match-in-progress",
                element: <MatchInProgress />,
                title: "match-in-progress",
                priv: true,
            },
            {
                path: "/talk",
                element: <TalkPage />,
                title: "talk",
                priv: true,
            },
            {
                path: "/setting",
                element: <SettingPage />,
                title: "setting",
                priv: true,
            },
            {
                path: "/profile-detail",
                element: <ProfileDetail />,
                title: "profile-detail",
                priv: true,
            },
            {
                path: "/chat-page",
                element: <ChatPage />,
                title: "chat-page",
                priv: true,
            },
            {
                path: "/age-confirmation",
                element: <AgeConfirmation />,
                title: "age-confirmation",
                priv: true,
            },
            {
                path: "/age-verification",
                element: <AgeVerification />,
                title: "age-verification",
                priv: true,
            },
            {
                path: "/health-insurance-card-others",
                element: <HealthInsuranceCardOthers />,
                title: "health-insurance-card-others",
                priv: true,
            },
            {
                path: "/anemone-plus",
                element: <AnemonePlus />,
                title: "anemone-plus",
                priv: true,
            },
            {
                path: "/platinum-option",
                element: <PlatinumOption />,
                title: "platinum-option",
                priv: true,
            },
            {
                path: "/secret-mode",
                element: <SecretMode />,
                title: "secret-mode",
                priv: true,
            },
            {
                path: "/billing-management",
                element: <BillingManagement />,
                title: "billing-management",
                priv: true,
            },
            {
                path: "/report-submission",
                element: <ReportSubmission />,
                title: "report-submission",
                priv: true,
            },
            {
                path: "/notification",
                element: <Notification />,
                title: "notification",
                priv: true,
            },
            {
                path: "/various-settings",
                element: <VariousSettings />,
                title: "various-settings",
                priv: true,
            },
            {
                path: "/notification-settings",
                element: <NotificationSettings />,
                title: "notification-settings",
                priv: true,
            },
            {
                path: "/emailaddress-change",
                element: <EmailAddressChange />,
                title: "emailaddress-change",
                priv: true,
            },
            {
                path: "/password-change",
                element: <PasswordChange />,
                title: "password-change",
                priv: true,
            },
            {
                path: "/search-conditions",
                element: <SearchConditions />,
                title: "search-conditions",
                priv: true,
            },
            {
                path: "/withdrawn",
                element: <Withdrawn />,
                title: "withdrawn",
                priv: true,
            },
            {
                path: "/withdrawal-questionnaire",
                element: <WithdrawalQuestionnaire />,
                title: "withdrawal-questionnaire",
                priv: true,
            },
            {
                path: "/withdrawal-procedures-notes",
                element: <WithdrawalProceduresNotes />,
                title: "withdrawal-procedures-notes",
                priv: true,
            },
            {
                path: "/secret-mode-settings",
                element: <SecretModeSettings />,
                title: "secret-mode-settings",
                priv: true,
            },
            {
                path: "/my-profile-detail",
                element: <MyProfileDetail />,
                title: "my-profile-detail",
                priv: true,
            },
            {
                path: "/payment",
                element: <Payment />,
                title: "payment",
                priv: true,
            },
            {
                path: "/inquiryform",
                element: <InquiryForm />,
                title: "inquiryform",
                priv: true,
            },
            {
                path: "/admin",
                element: <AdminPage />,
                title: "admin-page",
                priv: true,
                children: [
                    {
                        path: 'opinion',
                        element: <OpinionManage />,
                        title: "admin-opinion-manage",
                        priv: true
                    },
                    {
                        path: 'questionaire',
                        element: <QuestionaireManage />,
                        title: "admin-opinion-manage",
                        priv: true
                    },
                    {
                        path: 'report',
                        element: <ReportManage />,
                        title: "admin-opinion-manage",
                        priv: true
                    },
                ]
            },
        ],
    }
]);

export default routes;