import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
	// TODO: Use authentication token
    const authState = useSelector((state) => state.anemone);

	return authState?.token ? <Outlet /> : <Navigate to="/login"  replace />;
};

export default ProtectedRoutes;