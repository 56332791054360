import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const UnProtectedRoutes = () => {
	// TODO: Use authentication token
    const authState = useSelector((state) => state.anemone);
	return authState?.token ? <Navigate to="/setting"  replace /> : <Outlet />;
};

export default UnProtectedRoutes;