import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { baseInfoApi, loginApi, paymentApi, registerApi } from "./api";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const anemoneSlice = createSlice({
  name: "anemone",
  initialState: {},
  reducers: {
    setAccount: (state, action) => {
      const { user_id, email } = action.payload;
      return { ...state, user_id: user_id, email: email };
    },
    setToken: (state, action) => {
      const {token} = action.payload;
      return { ...state, token: token };
    },
    setLogin: (state, action) => {
      const { id, email, token, gender } = action.payload;
      return { ...state, user_id: id, email: email, token: token, gender: gender };
    },
    setLogout: (state, action) => ({})
  }
});

const persistedReducer = persistReducer(persistConfig, combineReducers({
  [paymentApi.reducerPath]: paymentApi.reducer,
  [registerApi.reducerPath]: registerApi.reducer,
  [baseInfoApi.reducerPath]: baseInfoApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  anemone: anemoneSlice.reducer
}));

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(paymentApi.middleware)
      .concat(registerApi.middleware)
      .concat(baseInfoApi.middleware)
      .concat(loginApi.middleware)
});


const persistor = persistStore(store)


export const {
  setAccount,
  setToken,
  setLogout,
  setLogin
} = anemoneSlice.actions;


export { store, persistor };

setupListeners(store.dispatch);