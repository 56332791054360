import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../Config/config";
import { 
    ADD_PERSONAL_DATA,
    CHECK_OTPCODE, 
    CREATE_ACCOUNT, 
    CREATE_PAYMENT, 
    GET_ADDRESS, 
    GET_USERS,
    LOGIN, 
    PHOTO_UPLOAD, 
    SEND_CREATE_DONE_EMAIL,
    SEND_OTPCODE 
} from "../Config/endpoint";

const PAYMENT_API_REDUCER = "paymentApi";
const CREATE_ACCOUNT_API_REDUCER = "create-account";
const BAES_INFO_API_REDUCER = "base-info";
const LOGIN_API_REDUCER = "login";

export const paymentApi = createApi({
    reducerPath: PAYMENT_API_REDUCER,
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: [PAYMENT_API_REDUCER],
    endpoints: (builder) => ({
        createPayment: builder.mutation({
            query: (data) => ({
                url: CREATE_PAYMENT,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: data,
            }),
        })
    }),
});

export const registerApi = createApi({
    reducerPath: CREATE_ACCOUNT_API_REDUCER,
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL
    }),
    tagTypes: [CREATE_ACCOUNT_API_REDUCER],
    endpoints: (builder) => ({
        createAccount: builder.mutation({
            query: (data) => ({
                url: CREATE_ACCOUNT,
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: data
            })
        }),
        sendOTPCode: builder.mutation({
            query: (data) => ({
                url: SEND_OTPCODE,
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: data
            })
        }),
        checkOTPCode: builder.mutation({
            query: (data) => ({
                url: CHECK_OTPCODE,
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: data
            })
        }),
        addPersonalData: builder.mutation({
            query: (data) => ({
                url: ADD_PERSONAL_DATA,
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: data
            })
        }),
        uploadPhoto: builder.mutation({
            query: (data) => ({
                method: "PUT",
                formData: true,
                url: PHOTO_UPLOAD,
                body: data
            })
        }),
        sendCreateAccountDoneMail: builder.mutation({
            query: (data) => ({
                method: "POST",
                formData: true,
                url: SEND_CREATE_DONE_EMAIL,
                body: data
            })
        })
    })
})

export const baseInfoApi = createApi({
    reducerPath: BAES_INFO_API_REDUCER,
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL
    }),
    tagTypes: [BAES_INFO_API_REDUCER],
    endpoints: (builder) => ({
        getAddress: builder.query({
            query: () => GET_ADDRESS,
        }),
        getUsers: builder.query({
            query: () => GET_USERS,
        })
    })
})

export const loginApi = createApi({
    reducerPath: LOGIN_API_REDUCER,
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL
    }),
    tagTypes: [LOGIN_API_REDUCER],
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (data) => ({
                method: "POST",
                formData: true,
                url: LOGIN,
                body: data
            })
        })
    })
})

export const { useCreateAccountMutation, useSendCreateAccountDoneMailMutation, useSendOTPCodeMutation, useCheckOTPCodeMutation, useAddPersonalDataMutation, useUploadPhotoMutation } = registerApi;

export const { useGetAddressQuery, useGetUsersQuery } = baseInfoApi;

export const { useCreatePaymentMutation } = paymentApi;

export const { useLoginMutation } = loginApi;