export const GET_TOKEN = "/get-token";
export const LOGIN_ENDPOINT = "/login";
export const CREATE_PAYMENT = "/create-payment-intent"
export const CREATE_ACCOUNT = "/create-account"
export const SEND_OTPCODE = "/send-code2mail"
export const CHECK_OTPCODE = "/check-code2mail"
export const GET_ADDRESS = "/get-address"
export const GET_USERS = "/get-users"
export const ADD_PERSONAL_DATA = "/insert-personal-detail"
export const PHOTO_UPLOAD = "/photo-upload"
export const SEND_CREATE_DONE_EMAIL = "/send-cad-mail"
export const LOGIN = "/login"